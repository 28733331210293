
import { useRouter } from "@/shared/useHelpers";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSectionAttendance",
  props: {
    courseId: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const router = useRouter();

    const viewCourseAttendance = (courseId: string) => {
      router.push({
        name: "courseAttendance",
        params: { id: courseId },
      });
    };

    return {
      viewCourseAttendance,
    };
  },
});
