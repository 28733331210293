
import { api } from "@/api/api";
import { downloadFile } from "@/api/file.api";
import { ApiGetMinSideCourseDto, ApiGetWorkingHourDto } from "@/api/generated/Api";
import { WorkingHourStatus } from "@/shared/enums/workingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { uniqueBy } from "@/shared/helpers/arrayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { beginDownloading } from "@/shared/helpers/otherHelpers";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSectionWorkingHours",
  props: {
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const uniquePendingPaymentWorkingHourFiles = ref<ApiGetWorkingHourDto[]>();

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        const initialWorkingHours = (
          await api.economy.getWorkingHoursSelfAsync({
            CourseId: props.courseId,
            Statuses: [WorkingHourStatus.PaymentPending],
            IsSelfEmployed: true,
          })
        ).data;

        //Filter out unique files
        uniquePendingPaymentWorkingHourFiles.value = uniqueBy(initialWorkingHours, (workingHour) => workingHour.fileId);
      });
    });

    return {
      headers,
      uniquePendingPaymentWorkingHourFiles,
      downloadWorkingHourFile,
      formatLocalizedDate,
    };
  },
});

const headers = [
  { text: "Filnavn", value: "fileName" },
  { text: "Handlinger", value: "actions" },
  { text: "Kontrakt-ID", value: "courseContractId" },
  { text: "Dato", value: "fileGeneratedByDate" },
  { text: "Generert av", value: "fileGeneratedByUserFullName" },
];

const downloadWorkingHourFile = async (workingHourEntry: ApiGetWorkingHourDto) => {
  globalLoadingWrapper({ blocking: true }, async () => {
    if (!workingHourEntry.fileId || !workingHourEntry.fileName) {
      return;
    }
    const fileBlob = (await downloadFile(workingHourEntry.fileId)).data;
    beginDownloading(fileBlob, workingHourEntry.fileName);
  });
};
