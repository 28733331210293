
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "BaseSection",
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
    showHideText: {
      type: String,
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
    },
  },
  setup() {
    const visible = ref(false);
    return { visible };
  },
});
