
import { api } from "@/api/api";
import { ApiGetMinSideCourseDto, ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { WorkingHourStatus } from "@/shared/enums/workingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
export default defineComponent({
  name: "ApprovedWorkingHoursTable",
  components: { BaseDatePicker },
  props: {
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const selectedRows = ref<ApiGetWorkingHourDto[]>([]);
    const approvedWorkingHours = ref<ApiGetWorkingHourDto[]>();
    const fromDate = ref("");
    const toDate = ref("");

    const reloadApprovedWorkingHours = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        approvedWorkingHours.value = (
          await api.economy.getWorkingHoursSelfAsync({
            CourseId: props.courseId,
            Statuses: [WorkingHourStatus.Approved],
            IsSelfEmployed: true,
            FromDate: fromDate.value,
            ToDate: toDate.value,
          })
        ).data;
      });
    };

    const submitWorkingHours = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.minside.setPaymentPendingWorkingHourMinSideAsync(
          selectedRows.value.map((workingHour) => workingHour.id)
        );
        openNotification(store, NotificationItemType.Success, "PDF-fil opprettet", 5000);
        await reloadApprovedWorkingHours();
      });
    };

    onMounted(() => reloadApprovedWorkingHours());

    return {
      headers,
      selectedRows,
      approvedWorkingHours,
      fromDate,
      toDate,
      submitWorkingHours,
      reloadApprovedWorkingHours,
      formatLocalizedDate,
    };
  },
});
const headers = [
  { text: "Type timer", value: "costTypeName" },
  { text: "Kontrakt-ID", value: "courseContractId" },
  { text: "Antall timer", value: "amount" },
  { text: "Dato", value: "date" },
  { text: "Status", value: "statusText" },
];
