var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', {
    attrs: {
      "color": "primary lighten-1",
      "small": "",
      "outlined": ""
    }
  }, [_vm.staffType === 'contact' ? [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-phone ")]), _vm._v(" Kontakt ")] : _vm._e(), _vm.staffType === 'supervisor' ? [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-arrow-right ")]), _vm._v(" Veileder ")] : _vm._e(), _vm.staffType === 'lecturer' ? [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-book-open-blank-variant ")]), _vm._v(" Foreleser ")] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }