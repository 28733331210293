var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": "Skriv ny melding",
      "submitButtonText": "Send melding",
      "modalType": _vm.modalType.Add
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('BaseLoading', {
    staticClass: "pa-4",
    attrs: {
      "value": _vm.loading
    }
  }, [_c('v-form', {
    ref: "messageForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "data-cy": "message-recipient"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Mottaker",
      "items": _vm.courses,
      "rules": [_vm.validateNotEmpty],
      "item-text": "label",
      "item-value": "value",
      "disabled": !!_vm.courseId,
      "dense": ""
    },
    model: {
      value: _vm.recipient,
      callback: function ($$v) {
        _vm.recipient = $$v;
      },
      expression: "recipient"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "message-subject",
      "label": "Emne",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    attrs: {
      "data-cy": "message-text",
      "filled": "",
      "label": "Melding",
      "auto-grow": "",
      "counter": "",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }