import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { api } from "@/api/api";
import { StatusType, EligibleDropOutStatus } from "@/shared/enums/courseApplicationStatus.enums";
import router from "@/router/router";
import { Store } from "vuex";
import { ApiGetMinSideCourseDto } from "@/api/generated/Api";
import { StoreState } from "@/store/store.state.interface";
import { isPast } from "date-fns";

export async function resignCourse(courseId: string, userStatus: string, store: Store<StoreState>) {
  try {
    await api.messaging.sendSystemCommunicationTemplateFromMinSideAsync({
      courseId: Number(courseId),
      communicationTemplateTag: "minside-deltaker-statusendring",
      dynamicVariables: [{ key: "{brukerDeltakerStatusNy}", value: getResignationValue(userStatus) }],
    });
    openNotification(store, NotificationItemType.Success, getNotificationValue(userStatus), 8000);
    router.push("/course");
  } catch (e) {
    openNotification(store, NotificationItemType.Error, "En feil oppsto under sending av melding", 8000);
  }
}

export function getResignationValue(userStatus: string) {
  return Object.values(EligibleDropOutStatus).includes(userStatus as EligibleDropOutStatus)
    ? StatusType.Avmeldt
    : StatusType.Påmeldt;
}

export function getCourseConfirmationText(userStatus: string, course: ApiGetMinSideCourseDto) {
  if (Object.values(EligibleDropOutStatus).includes(userStatus.toLowerCase() as EligibleDropOutStatus)) {
    if (course.unenrollmentDeadline && isPast(new Date(course.unenrollmentDeadline))) {
      return "Avmeldingsfrist er passert, bekreft her for å melde deg av kurset/studiet. Vi gjør oppmerksom på at gebyr kan påløpe. Viser til kurs-/studiebestemmelser for informasjon";
    }
    return "Ønsker du å melde deg av: bekreft her for å melde deg av kurset/studiet";
  }
  if (course.enrollmentDeadline && isPast(new Date(course.enrollmentDeadline))) {
    return "Påmeldingsfrist er passert, bekreft her for å melde deg på kurset/studiet";
  }
  return "Ønsker du å melde deg på kurset igjen, bekreft her";
}

export function getNotificationValue(userStatus: string) {
  return Object.values(EligibleDropOutStatus).includes(userStatus.toLowerCase() as EligibleDropOutStatus)
    ? "Avmelding er sendt til administrasjonen"
    : "Påmelding er sendt til administrasjonen";
}

export function getConfirmationHeaderValue(userStatus: string) {
  return Object.values(EligibleDropOutStatus).includes(userStatus.toLowerCase() as EligibleDropOutStatus)
    ? "Bekreft avmelding"
    : "Bekreft påmelding";
}
