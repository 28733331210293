
import { api } from "@/api/api";
import {
  ApiGetContractLineDtoType,
  ApiGetContractWithFileDto,
  ApiGetMinSideCourseDto,
  ApiGetWorkingHourDto,
} from "@/api/generated/Api";
import ApprovedWorkingHoursTable from "@/components/courses/WorkingHours/ApprovedWorkingHoursTable.vue";
import ContractTable from "@/components/courses/WorkingHours/ContractTable.vue";
import WorkingHoursGeneratedFiles from "@/components/courses/WorkingHours/WorkingHoursGeneratedFiles.vue";
import WorkingHoursTable from "@/components/courses/WorkingHours/WorkingHoursTable.vue";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import { CourseContractStatus } from "@/shared/enums/courseContractStatus.enum";
import { CourseStatus } from "@/shared/enums/courseStatus.enum";
import { WorkingHourStatus } from "@/shared/enums/workingHourStatus.enum";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "WorkingHoursPage",
  components: {
    WorkingHoursTable,
    BaseBreadCrumbs,
    ApprovedWorkingHoursTable,
    WorkingHoursGeneratedFiles,
    ContractTable,
  },
  setup() {
    const route = useRoute();
    const course = ref<ApiGetMinSideCourseDto>();
    const workingHours = ref<ApiGetWorkingHourDto[]>([]);
    const signedContracts = ref<ApiGetContractWithFileDto[]>([]);
    const contractLines = ref<ApiGetContractLineDtoType[]>([]);
    const availableContractLines = ref<ApiGetContractLineDtoType[]>([]);
    const courseId = +route.params.id;
    const currentTab = ref("workingHours");

    const approvedWorkingHours = computed(() =>
      workingHours.value.filter((workingHourEntry) => workingHourEntry.status !== WorkingHourStatus.NotApproved)
    );

    const selfEmployedContracts = computed(() => signedContracts.value.some((contract) => contract.isSelfEmployed));

    const loadWorkingHoursByCourseId = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const [workingHoursResponse, contractsSelf, courseContractLines] = await Promise.all([
          api.economy.getWorkingHoursSelfAsync({ CourseId: courseId }),
          api.economy.getContractsForCourseAndSelfAsync(courseId, {
            Statuses: [
              CourseContractStatus.Downloaded,
              CourseContractStatus.Signed,
              CourseContractStatus.DownloadFailed,
            ],
          }),
          api.course.getCourseContractLines(courseId),
        ]);
        workingHours.value = workingHoursResponse.data;
        signedContracts.value = contractsSelf.data;
        contractLines.value = courseContractLines.data;
        availableContractLines.value = contractLines.value.sort((a, b) => b.remainingAmount - a.remainingAmount);
      });
    };

    const loadCourse = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        course.value = (await api.minside.getCourseByIdAsync(courseId)).data;
      });
    };

    onMounted(async () => {
      await Promise.all([loadCourse(), loadWorkingHoursByCourseId()]);
    });

    return {
      course,
      currentTab,
      courseId,
      workingHours,
      signedContracts,
      contractLines,
      selfEmployedContracts,
      approvedWorkingHours,
      loadWorkingHoursByCourseId,
      isCourseClosed: computed(() => course.value?.status === CourseStatus.Avsluttet),
      availableContractLines,
      isMobile,
    };
  },
});
