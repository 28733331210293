var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', [_c('v-container', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 font-weight-regular pt-4 pb-4",
    attrs: {
      "data-cy": "courseSectionStaffLabel",
      "col": ""
    }
  }, [_vm._v(" Undervisere ")]), _c('v-col', {
    staticClass: "text-h6 font-weight-regular pt-0 pb-0 d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "mt-3 mr-2 ml-2 pt-2 pb-2",
    attrs: {
      "color": "primary",
      "small": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.toggleList();
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm.visible ? "Skjul" : "Vis") + " undervisere")) + " ")])], 1)], 1)], 1)], 1), _vm.visible ? _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.teachers, function (teacher) {
    return _c('v-list-item', {
      key: teacher.userId
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-account")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(teacher.userFullName)
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }