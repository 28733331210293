var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": _vm.color,
      "small": "",
      "outlined": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]), _vm._v(" " + _vm._s(_vm.label) + " ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }