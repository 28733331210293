import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { CourseStatus } from "@/shared/enums/courseStatus.enum";

/**
 * Filters the array by the provided unique property.
 * If multiple are found, the last one will be selected.
 * @param array The array to filter
 * @param propertySelector The callback function to select the value to be filtered on
 * @returns A new array with deep cloned elements
 */
export function uniqueBy<T>(array: T[], propertySelector: (element: T) => any): T[] {
  const uniquePropElemRec = array.reduce<Record<any, T>>((uniquePropElem, element) => {
    const property = propertySelector(element);
    return property ? { ...uniquePropElem, [property]: deepCloneObject(element) } : uniquePropElem;
  }, {});
  return Object.values(uniquePropElemRec);
}

const orderedCourseStatus: { [key in string]: number } = {
  [CourseStatus.Åpnet]: 1,
  [CourseStatus.Planlagt]: 2,
  [CourseStatus.Utsatt]: 3,
  [CourseStatus.Avsluttet]: 4,
  [CourseStatus.Innstilt]: 5,
  [CourseStatus.Slettet]: 6,
};

export const sortByCourseStatus = (a: string, b: string) => {
  return orderedCourseStatus[a] - orderedCourseStatus[b];
};

export const localeSortByProperty =
  <ObjectType>(key: keyof ObjectType, desc?: boolean) =>
  (a: ObjectType, b: ObjectType) => {
    const compareA = `${a[key] ?? ""}`.trim();
    const compareB = `${b[key] ?? ""}`.trim();
    return desc ? compareB.localeCompare(compareA) : compareA.localeCompare(compareB);
  };
