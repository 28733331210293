var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: {
      'green--text': _vm.remainingHours < _vm.totalAmountOfHours,
      'orange--text': _vm.remainingHours === _vm.totalAmountOfHours,
      'red--text': _vm.remainingHours > _vm.totalAmountOfHours
    }
  }, [_vm._v(" " + _vm._s(_vm.displayText) + " "), _vm.remainingHours < _vm.totalAmountOfHours ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "green",
      "small": ""
    }
  }, [_vm._v("mdi-check-bold")]) : _vm._e(), _vm.remainingHours === _vm.totalAmountOfHours ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "orange",
      "small": ""
    }
  }, [_vm._v("mdi-alert-rhombus")]) : _vm._e(), _vm.remainingHours > _vm.totalAmountOfHours ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "red",
      "small": ""
    }
  }, [_vm._v("mdi-cancel")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }