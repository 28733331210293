
import { defineComponent } from "@vue/composition-api";

interface ComponentData {
  iconTypes: any;
  colorTypes: any;
}

export default defineComponent({
  name: "CourseInfoChip",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data: function (): ComponentData {
    return {
      iconTypes: {
        positive: "mdi-check",
        negative: "mdi-close",
      },
      colorTypes: {
        positive: "green",
        negative: "red",
      },
    };
  },
  computed: {
    icon(): any {
      return this.value ? this.iconTypes.positive : this.iconTypes.negative;
    },
    color(): any {
      return this.value ? this.colorTypes.positive : this.colorTypes.negative;
    },
  },
});
