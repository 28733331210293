
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";

interface ComponentData {
  to: string;
  subject: string;
  content: string;
}

export default defineComponent({
  name: "TheCourseNewEmailModal",
  components: { BaseModalForm },
  data: function (): ComponentData {
    return {
      to: "",
      subject: "",
      content: "",
    };
  },
  computed: {
    ...mapGetters("messages", ["getRecipents"]),
    recipents() {
      return this.getRecipents;
    },
  },
});
