var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.availableContractLines,
      "sort-by": "date",
      "sort-desc": true,
      "hide-default-footer": true
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }