var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.sendMessageModalVisible ? _c('BaseModal', [_c('SendMessageModal', {
    attrs: {
      "modalType": _vm.modalData.modalType,
      "courseId": _vm.courseId
    },
    on: {
      "close": _vm.closeSendMessageModal
    }
  })], 1) : _vm._e(), !_vm.isCourseClosed ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": _vm.isMobile ? 'primary' : 'white',
      "small": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.sendMessage();
      }
    }
  }, [_vm._v("Send melding")]) : _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "small": "",
            "outlined": "",
            "disabled": ""
          }
        }, [_vm._v(" Send melding ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Kan ikke sende meldinger på et avsluttet kurs")])]), _vm.canUserResign && !_vm.canSeeButton ? _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "290"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ma-2",
          attrs: {
            "color": _vm.isMobile ? 'primary' : 'white',
            "small": "",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Meld av")])];
      }
    }], null, false, 401695983),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.courseConfirmationHeader) + " ")]), _c('v-card-text', [_vm._v(_vm._s(_vm.courseConfirmationInfo))]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": _vm.resignFromCourse
    }
  }, [_vm._v(" Bekreft ")]), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Avbryt ")])], 1)], 1)], 1) : _vm._e(), !_vm.canUserResign && !_vm.canSeeButton ? _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "290"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ma-2",
          attrs: {
            "color": _vm.isMobile ? 'primary' : 'white',
            "small": "",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Meld på")])];
      }
    }], null, false, 952547597),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.courseConfirmationHeader) + " ")]), _c('v-card-text', [_vm._v(_vm._s(_vm.courseConfirmationInfo))]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": _vm.resignFromCourse
    }
  }, [_vm._v(" Bekreft ")]), _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Avbryt ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }