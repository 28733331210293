
import { api } from "@/api/api";
import { ApiGetContractLineDtoType, ApiGetMinSideCourseDto, ApiGetWorkingHourDto } from "@/api/generated/Api";
import WorkingHoursModal from "@/components/courses/WorkingHours/WorkingHoursModal.vue";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import BaseSection from "@/components/shared/section/BaseSection.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { WorkingHourStatus } from "@/shared/enums/workingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import BaseInfoField from "@/components/shared/field/BaseInfoField.vue";
import ContractTable from "@/components/courses/WorkingHours/ContractTable.vue";

export default defineComponent({
  name: "CourseSectionWorkingHours",
  components: { BaseInfoField, BaseSection, BaseModal, WorkingHoursModal, BaseBreadCrumbs, ContractTable },
  emits: ["workingHoursUpdate"],
  props: {
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
    contractLines: {
      type: Array as PropType<ApiGetContractLineDtoType[]>,
      required: true,
    },
    workingHours: {
      type: Array as PropType<ApiGetWorkingHourDto[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const modalData = ref(getInitialModalData());
    const isLoading = ref(false);

    const search = ref("");

    const newWorkingHourEntry = useOpenModal(
      ModalType.Add,
      `arbeidstimer (${props.courseId} - ${props.course.externalTitle})`,
      modalData
    );
    const displayWorkingHourEntry = useOpenModal(
      ModalType.Display,
      `kostnad (${props.courseId} - ${props.course.externalTitle})`,
      modalData
    );
    const editWorkingHourEntry = useOpenModal(
      ModalType.Edit,
      `arbeidstimer (${props.courseId} - ${props.course.externalTitle})`,
      modalData
    );

    const deleteWorkingHourEntry = async (id: number) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.economy.deleteWorkingHourAsync(id);
        emit("workingHoursUpdate");
      });
    };

    const handleUpdate = () => {
      emit("workingHoursUpdate");
    };

    return {
      headers,
      modalData,
      isLoading,
      search,
      newWorkingHourEntry,
      displayWorkingHourEntry,
      editWorkingHourEntry,
      formatLocalizedDate,
      deleteWorkingHourEntry,
      handleUpdate,
      workingHoursIsNotApproved: (status: WorkingHourStatus) =>
        status === WorkingHourStatus.NotApproved || status === WorkingHourStatus.Declined,
    };
  },
});

const headers = [
  { text: "Kontrakt-ID", value: "courseContractId" },
  { text: "Handlinger", value: "actions" },
  { text: "Type timer", value: "costTypeName" },
  { text: "Antall timer", value: "amount" },
  { text: "Dato", value: "date" },
  { text: "Status", value: "statusText" },
];
