
import { api } from "@/api/api";
import {
  ApiCreateWorkingHourDto,
  ApiGetContractLineDtoType,
  ApiGetMinSideCourseDto,
  ApiGetWorkingHourDto,
} from "@/api/generated/Api";
import WorkingHoursRemainingSpan from "@/components/courses/WorkingHours/WorkingHoursRemainingSpan.vue";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { CostType } from "@/shared/enums/costType.enum";
import { CourseStatus } from "@/shared/enums/courseStatus.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { WorkingHourStatus } from "@/shared/enums/workingHourStatus.enum";
import { formatLocalizedDate, getRoundedHours } from "@/shared/helpers/dateHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { differenceInMinutes, isBefore, isSameDay } from "date-fns";

const getWorkingHourInitialData = (): ApiCreateWorkingHourDto => ({
  courseContractLineId: 0,
  amount: 0,
  date: "",
});

interface WorkingHourTypeInterface {
  name?: string | null;
  amount?: number | null;
  date: string;
  isAllAttendancesRegistered: boolean;
  workingHoursRegistered: number;
}

export default defineComponent({
  name: "WorkingHoursModal",
  components: { BaseModalForm, BaseDatePicker, WorkingHoursRemainingSpan },
  emits: ["updateTableValues", "closeModal"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    workingHoursEntry: {
      type: Object as PropType<ApiCreateWorkingHourDto>,
      required: false,
      default: getWorkingHourInitialData,
    },
    workingHoursEntryId: {
      type: Number,
      required: false,
      default: 0,
    },
    contractLines: {
      type: Array as PropType<ApiGetContractLineDtoType[]>,
      required: true,
    },
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    allWorkingHourEntries: {
      type: Array as PropType<ApiGetWorkingHourDto[]>,
      required: true,
    },
  },

  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const workingHourValues = ref(deepCloneObject(props.workingHoursEntry));
    const selectedCostType = ref<ApiGetContractLineDtoType>();

    const getRemainingApprovedHours = computed(() => {
      if (!selectedCostType.value) {
        return 0;
      }
      if (ModalType.Display) {
        selectedCostType.value.remainingApprovedAmount;
      }
      if (ModalType.Edit) {
        return (
          selectedCostType.value.remainingApprovedAmount -
          (workingHourValues.value.amount - props.workingHoursEntry.amount)
        );
      }
      return selectedCostType.value.remainingApprovedAmount - workingHourValues.value.amount;
    });

    const getRemainingRegisteredHours = computed(() => {
      if (!selectedCostType.value) {
        return 0;
      }
      if (ModalType.Display) {
        selectedCostType.value.remainingAmount;
      }
      if (ModalType.Edit) {
        return (
          selectedCostType.value.remainingAmount - (workingHourValues.value.amount - props.workingHoursEntry.amount)
        );
      }
      return selectedCostType.value.remainingAmount - workingHourValues.value.amount;
    });

    const isCourseClosed = props.course.status === CourseStatus.Avsluttet;

    const getAvailableContractLines = computed(() => {
      const availableContracLines = props.contractLines.sort((a, b) => b.remainingAmount - a.remainingAmount);

      if (isCourseClosed) {
        return availableContracLines.filter((x) => x.costTypeName === CostType.OtherHours);
      }

      return availableContracLines;
    });

    const getAvailableClasses = computed(() => {
      if (!props.course.plan || !props.course.plan.schedules) {
        return;
      }
      // Count up the working hours for each day (will later be mapped per schedule
      // entry to display information on how many working hours remaining for a given schedule date)
      const dateWorkingHourAmountRec = props.allWorkingHourEntries.reduce<Record<number, number>>(
        (dateWorkingHourAmount, workingHour) => {
          if (
            workingHour.status === WorkingHourStatus.Declined ||
            workingHour.costTypeName !== CostType.TeachingHours
          ) {
            return dateWorkingHourAmount;
          }
          const time = new Date(workingHour.date).getTime();
          return { ...dateWorkingHourAmount, [time]: (dateWorkingHourAmount[time] ?? 0) + workingHour.amount };
        },
        {}
      );

      return props.course.plan.schedules
        .map((scheduleItem) => {
          const startDate = new Date(scheduleItem.start);
          const endDate = new Date(scheduleItem.end);
          if (isBefore(endDate, Date.now()) || isSameDay(endDate, Date.now())) {
            return {
              name: scheduleItem.title,
              amount: differenceInMinutes(endDate, startDate) / 60,
              date: scheduleItem.start,
              isAllAttendancesRegistered: scheduleItem.isAllAttendancesRegistered,
              workingHoursRegistered: dateWorkingHourAmountRec[new Date(scheduleItem.start).getTime()] ?? 0,
            };
          }
        })
        .filter(Boolean);
    });

    const teachingHoursItemText = (workingHourType: WorkingHourTypeInterface) =>
      `${workingHourType.name} - ${formatLocalizedDate(workingHourType.date)}, ${
        workingHourType.amount && getRoundedHours(workingHourType.amount)
      } (${
        props.course.plan?.registerHours && !workingHourType.isAllAttendancesRegistered
          ? "fremmøte mangler"
          : `førte timer: ${workingHourType.workingHoursRegistered}`
      })`;

    const costTypeName = (contractLine: ApiGetContractLineDtoType) =>
      `${contractLine.courseContractId} - ${contractLine.costTypeName} - ${contractLine.description}`;

    const isCostTypeDisabled = (contractLine: ApiGetContractLineDtoType) => contractLine.remainingAmount <= 0;

    const validateRemainingHours = () => {
      if (getRemainingApprovedHours.value >= 0 && getRemainingRegisteredHours.value >= 0) {
        return true;
      }
      return "Du har ikke nok gjenstående timer på denne kontrakten";
    };

    const setSelectedCostType = (selectedCostTypeId: number) => {
      selectedCostType.value = getAvailableContractLines.value.find((costType) => costType.id === selectedCostTypeId);
    };

    const changeCourseContract = (selectedCostTypeId: number) => {
      workingHourValues.value.date = "";
      workingHourValues.value.amount = 0;
      setSelectedCostType(selectedCostTypeId);
    };

    onMounted(() => {
      if (workingHourValues.value.courseContractLineId) {
        setSelectedCostType(workingHourValues.value.courseContractLineId);
      }
    });

    const selectedInstructionClass = (selectedClassDate: string) => {
      if (!getAvailableClasses.value) {
        return;
      }
      const selectedClassValues = getAvailableClasses.value.find(
        (availableClass) => availableClass?.date === selectedClassDate
      );
      if (!selectedClassValues || !selectedClassValues.amount) {
        return;
      }
      workingHourValues.value.amount = selectedClassValues.amount;
    };

    const handleSubmit = async () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid || !workingHourValues.value) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        if (props.modalType === ModalType.Add) {
          await api.economy.createWorkingHourAsync(workingHourValues.value);
          openNotification(store, NotificationItemType.Success, "Arbeidstimer registrert", 5000);
        } else if (props.modalType === ModalType.Edit) {
          if (!props.workingHoursEntryId) {
            return;
          }
          await api.economy.updateWorkingHourAsync(props.workingHoursEntryId, workingHourValues.value);
          openNotification(store, NotificationItemType.Success, "Endringene er lagret", 5000);
        }
        emit("updateTableValues");
        emit("closeModal");
      });
    };

    return {
      workingHourValues,
      validateRemainingHours,
      getAvailableContractLines,
      selectedCostType,
      getRemainingApprovedHours,
      getRemainingRegisteredHours,
      teachingHoursItemText,
      changeCourseContract,
      selectedInstructionClass,
      getAvailableClasses,
      CostType,
      costTypeName,
      isCostTypeDisabled,
      handleSubmit,
      validateNotEmpty,
      readonly: computed(() => props.modalType === ModalType.Display),
    };
  },
});
