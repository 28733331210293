var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": "Opprett ny melding"
    },
    on: {
      "submit": _vm.onSubmit,
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', {
    attrs: {
      "items": _vm.getRecipents,
      "item-text": "name",
      "item-value": "id",
      "search-input": _vm.search,
      "hide-selected": "",
      "label": "Til",
      "return-object": "",
      "multiple": "",
      "persistent-hint": "",
      "small-chips": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      }
    },
    model: {
      value: _vm.recipents,
      callback: function ($$v) {
        _vm.recipents = $$v;
      },
      expression: "recipents"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Legg til et emne",
      "hide-details": "",
      "required": ""
    },
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Skriv melding her",
      "hide-details": "",
      "required": ""
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }