var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', [_vm._v(" Fremmøte "), _c('v-spacer'), _c('v-text-field', {
    staticClass: "pa-0 ma-0 mt-2",
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Søk",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.scheduledClasses,
      "search": _vm.search,
      "sort-by": "end",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.end",
      fn: function (_ref) {
        var value = _ref.value;
        return [_c('span', [_vm._v(_vm._s(_vm.formatLocalizedDate(value) || ""))])];
      }
    }, {
      key: "item.start",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(_vm.getDifferenceInHours(item) || ""))])];
      }
    }, {
      key: "item.isAllAttendancesRegistered",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "primary",
            "data-cy": "viewCourseAttendanceInfo"
          },
          on: {
            "click": function ($event) {
              return _vm.linkCourseAttendanceInfo(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-arrow-right")])], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }