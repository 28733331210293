var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_c('span', {
    attrs: {
      "data-cy": "approvedWorkingHoursTableTitle"
    }
  }, [_vm._v(" Godkjente timer - " + _vm._s(_vm.course.courseName) + " (ID " + _vm._s(_vm.courseId) + ") ")]), _c('v-spacer'), _c('BaseDatePicker', {
    attrs: {
      "label": "Fra"
    },
    on: {
      "input": function ($event) {
        return _vm.reloadApprovedWorkingHours();
      }
    },
    model: {
      value: _vm.fromDate,
      callback: function ($$v) {
        _vm.fromDate = $$v;
      },
      expression: "fromDate"
    }
  }), _c('BaseDatePicker', {
    attrs: {
      "label": "Til"
    },
    on: {
      "input": function ($event) {
        return _vm.reloadApprovedWorkingHours();
      }
    },
    model: {
      value: _vm.toDate,
      callback: function ($$v) {
        _vm.toDate = $$v;
      },
      expression: "toDate"
    }
  }), _vm.selectedRows.length > 0 ? _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitWorkingHours();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-content-save")]), _vm._v(" Lagre som PDF ")], 1) : _vm._e()], 1), _c('v-data-table', {
    attrs: {
      "data-cy": "approvedWorkingHoursTable",
      "headers": _vm.headers,
      "items": _vm.approvedWorkingHours,
      "sort-by": "approvedByDate",
      "sort-desc": true,
      "item-key": "id",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "item.date",
      fn: function (_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDate(value) : "") + " ")];
      }
    }, {
      key: "item.approvedByDate",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDate(value) : "") + " ")];
      }
    }, {
      key: "item.comment",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "max-width": "50%",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "commentTooltip"
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-comment-text-outline")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(value))])]) : _vm._e()];
      }
    }], null, true),
    model: {
      value: _vm.selectedRows,
      callback: function ($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }