var render = function () {
  var _vm$course$mainCourse;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subtitle-1 pa-2 py-0 headerColor"
  }, [_vm._v("Informasjon:")])]), _vm._l(_vm.courseDetails, function (item, i) {
    return _c('v-col', {
      key: (i + "-courseDetails-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseInfoField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": (i + "-courseDetails-field")
      }
    })], 1);
  }), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subtitle-1 pa-2 py-0 headerColor"
  }, [_vm._v("Adresse:")])]), _vm._l(_vm.address, function (item, i) {
    return _c('v-col', {
      key: (i + "-address-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseInfoField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": (i + "-address-field")
      }
    })], 1);
  }), !_vm.isResource ? _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "subtitle-1 pa-2 py-0 headerColor"
  }, [_vm._v("Dato og klokke:")])]) : _vm._e(), !_vm.isResource ? _vm._l(_vm.dateAndtime, function (item, i) {
    return _c('v-col', {
      key: (i + "-dateAndTime-field"),
      staticClass: "pt-0",
      attrs: {
        "cols": "12",
        "xs": "12",
        "sm": "6",
        "md": "6",
        "lg": "4"
      }
    }, [_c('BaseInfoField', {
      attrs: {
        "value": item.value,
        "label": item.label,
        "dataCy": (i + "-dateAndTime-field")
      }
    })], 1);
  }) : _vm._e(), _vm.course.webDescription ? _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('h3', {
    staticClass: "subtitle-1 pa-2 py-0 headerColor"
  }, [_vm._v(" " + _vm._s(_vm.useIsVocationalSchool((_vm$course$mainCourse = _vm.course.mainCourseArea) !== null && _vm$course$mainCourse !== void 0 ? _vm$course$mainCourse : "") ? "Studiebeskrivelse" : "Kursbeskrivelse") + ": ")]), _c('v-expansion-panels', {
    staticClass: "pt-3"
  }, _vm._l(_vm.infoTexts, function (item, i) {
    return _c('v-expansion-panel', {
      key: i
    }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(item.title))]), _c('v-expansion-panel-content', [_c('div', {
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    })])], 1);
  }), 1)], 1) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }