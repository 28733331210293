var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 font-weight-regular py-1",
    attrs: {
      "col": ""
    }
  }, [_vm._v(" Fremmøte ")]), _c('v-col', {
    staticClass: "text-h6 font-weight-regular pt-0 pb-0 d-flex justify-end",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-btn', {
    staticClass: "my-1",
    attrs: {
      "color": "primary",
      "small": "",
      "outlined": "",
      "data-cy": "viewCourseAttendance"
    },
    on: {
      "click": function ($event) {
        return _vm.viewCourseAttendance(_vm.courseId);
      }
    }
  }, [_vm._v(" Vis fremmøte ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }