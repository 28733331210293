var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('closeModal');
      },
      "submit": _vm.handleSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Velg kontrakt",
      "items": _vm.getAvailableContractLines,
      "readonly": _vm.readonly,
      "rules": [_vm.validateNotEmpty],
      "item-text": _vm.costTypeName,
      "item-disabled": _vm.isCostTypeDisabled,
      "item-value": "id"
    },
    on: {
      "change": _vm.changeCourseContract
    },
    model: {
      value: _vm.workingHourValues.courseContractLineId,
      callback: function ($$v) {
        _vm.$set(_vm.workingHourValues, "courseContractLineId", $$v);
      },
      expression: "workingHourValues.courseContractLineId"
    }
  })], 1), _vm.selectedCostType ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('WorkingHoursRemainingSpan', {
    attrs: {
      "displayText": ("Registrerte timer: " + (_vm.selectedCostType.amount - _vm.getRemainingRegisteredHours) + " av \n          " + (_vm.selectedCostType.amount)),
      "remainingHours": _vm.selectedCostType.amount - _vm.getRemainingRegisteredHours,
      "totalAmountOfHours": _vm.selectedCostType.amount
    }
  })], 1) : _vm._e(), _vm.selectedCostType ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('WorkingHoursRemainingSpan', {
    attrs: {
      "displayText": ("Timer godkjent av AOF: " + (_vm.selectedCostType.amount - _vm.getRemainingApprovedHours) + " av \n          " + (_vm.selectedCostType.amount)),
      "remainingHours": _vm.selectedCostType.amount - _vm.getRemainingApprovedHours,
      "totalAmountOfHours": _vm.selectedCostType.amount
    }
  })], 1) : _vm._e(), _vm.selectedCostType ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" Sats: "), _c('strong', [_vm._v(_vm._s(_vm.selectedCostType.price && _vm.selectedCostType.price.toFixed(2)))])]) : _vm._e(), _vm.selectedCostType ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" Beskrivelse: "), _c('strong', [_vm._v(_vm._s(_vm.selectedCostType.description))])]) : _vm._e(), _vm.selectedCostType && _vm.selectedCostType.costTypeName !== _vm.CostType.TeachingHours ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Dato",
      "readonly": _vm.readonly,
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.workingHourValues.date,
      callback: function ($$v) {
        _vm.$set(_vm.workingHourValues, "date", $$v);
      },
      expression: "workingHourValues.date"
    }
  })], 1) : _vm._e(), _vm.selectedCostType && _vm.selectedCostType.costTypeName === _vm.CostType.TeachingHours ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Velg dag",
      "readonly": _vm.readonly,
      "items": _vm.getAvailableClasses,
      "item-text": _vm.teachingHoursItemText,
      "item-value": "date"
    },
    on: {
      "change": _vm.selectedInstructionClass
    },
    model: {
      value: _vm.workingHourValues.date,
      callback: function ($$v) {
        _vm.$set(_vm.workingHourValues, "date", $$v);
      },
      expression: "workingHourValues.date"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Antall timer (bruk punktum som desimalskilletegn)",
      "type": "number",
      "readonly": _vm.readonly,
      "rules": [_vm.validateNotEmpty, _vm.validateRemainingHours]
    },
    model: {
      value: _vm.workingHourValues.amount,
      callback: function ($$v) {
        _vm.$set(_vm.workingHourValues, "amount", $$v);
      },
      expression: "workingHourValues.amount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "Kommentar",
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.workingHourValues.comment,
      callback: function ($$v) {
        _vm.$set(_vm.workingHourValues, "comment", $$v);
      },
      expression: "workingHourValues.comment"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }