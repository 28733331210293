
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSectionStaffType",
  props: {
    staffType: {
      type: String,
      required: true,
    },
  },
});
