
import { ApiGetContractLineDtoType } from "@/api/generated/Api";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ContractTable",
  props: {
    availableContractLines: {
      type: Array as PropType<ApiGetContractLineDtoType[]>,
      required: true,
    },
  },

  setup() {
    return {
      headers,
    };
  },
});

const headers = [
  { text: "Kontrakt-ID", value: "courseContractId" },
  { text: "Type timer", value: "costTypeName" },
  { text: "Kontraktstimer", value: "amount" },
  { text: "Registrerte timer", value: "workingHours" },
  { text: "Gjenstående timer", value: "remainingAmount" },
  { text: "Godkjente timer", value: "approvedWorkingHours" },
];
