
import { ApiGetCourseParticipantDto } from "@/api/generated/Api";
import CourseSectionStaffType from "@/components/courses/section/CourseSectionStaffType.vue";

import { defineComponent, ref, PropType } from "@vue/composition-api";

export default defineComponent({
  components: { CourseSectionStaffType },
  name: "CourseSectionStaff",
  props: {
    teachers: {
      type: Array as PropType<ApiGetCourseParticipantDto[]>,
      required: true,
    },
  },
  setup() {
    const visible = ref(false);

    function toggleList() {
      visible.value = !visible.value;
    }

    return {
      visible,
      toggleList,
    };
  },
});
