
import SendMessageModal from "@/components/messages/SendMessageModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { computed, defineComponent, ref, onMounted } from "@vue/composition-api";
import { useRoute, useStore } from "@/shared/useHelpers";
import { getInitialModalData } from "@/shared/helpers/modalHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType } from "@vue/composition-api";
import { api } from "@/api/api";
import {
  resignCourse,
  getCourseConfirmationText,
  getConfirmationHeaderValue,
} from "@/shared/helpers/courseStatusHelper";
import { ApiGetMinSideCourseDto } from "@/api/generated/Api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { EligibleDropOutStatus } from "@/shared/enums/courseApplicationStatus.enums";
import { CourseStatus } from "@/shared/enums/courseStatus.enum";

export default defineComponent({
  name: "CourseSectionHeader",
  components: { BaseModal, SendMessageModal },
  props: {
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    userStatus: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const sendMessageModalVisible = ref(false);
    const courseId = route.params.id.toString();
    const modalData = ref(getInitialModalData());
    const resource = computed(() => store.state.hoc.resource);
    const dialog = ref(false);
    const courses = ref<ApiGetMinSideCourseDto>();

    const courseConfirmationHeader = computed(() => getConfirmationHeaderValue(props.userStatus));

    const canUserResign = Object.values(EligibleDropOutStatus).includes(props.userStatus as EligibleDropOutStatus);

    const canSeeButton =
      props.userStatus === "fullført" ||
      props.userStatus === "deltatt" ||
      props.userStatus === "avslått" ||
      props.userStatus === "ikke deltatt";

    const courseConfirmationInfo = computed(() => {
      if (!courses.value) {
        return;
      }
      return getCourseConfirmationText(props.userStatus, courses.value);
    });

    const sendMessage = () => {
      sendMessageModalVisible.value = true;
    };
    const closeSendMessageModal = () => {
      sendMessageModalVisible.value = false;
    };

    const resignFromCourse = () => {
      resignCourse(courseId, props.userStatus, store);
    };

    const loadCourse = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        courses.value = (await api.minside.getCourseByIdAsync(+route.params.id)).data;
      });
    };

    onMounted(async () => {
      await loadCourse();
    });

    return {
      isMobile,
      sendMessageModalVisible,
      courseId,
      modalData,
      resource,
      closeSendMessageModal,
      sendMessage,
      resignFromCourse,
      dialog,
      courses,
      courseConfirmationHeader,
      courseConfirmationInfo,
      canUserResign,
      canSeeButton,
      isCourseClosed: computed(
        () => props.course.plan?.registerHours && props.course.status === CourseStatus.Avsluttet
      ),
    };
  },
});
