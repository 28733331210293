
import { ApiGetCourseScheduleDtoType, ApiGetMinSideCourseDto } from "@/api/generated/Api";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { differenceInHours } from "date-fns";

export default defineComponent({
  name: "TheCourseAttendanceList",
  components: {},
  props: {
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    scheduledClasses: {
      type: Array as PropType<ApiGetCourseScheduleDtoType[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const search = ref("");
    const courseId = router.currentRoute.params.id;

    const getDifferenceInHours = (courseEvent: ApiGetCourseScheduleDtoType) =>
      differenceInHours(new Date(courseEvent.end), new Date(courseEvent.start));

    const linkCourseAttendanceInfo = (item: any) => {
      router.push({
        name: "courseAttendanceParticipant",
        params: { courseId: courseId, scheduleStart: item.start },
      });
    };
    const linkCourse = () => {
      router.push({
        name: "singleAssignmentCourse",
        params: { id: courseId },
      });
    };

    return {
      headers,
      search,
      courseId,
      linkCourseAttendanceInfo,
      getDifferenceInHours,
      linkCourse,
      formatLocalizedDate,
    };
  },
});

const headers = [
  {
    text: "Dato",
    value: "end",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Tittel",
    value: "title",
  },
  {
    text: "Alt fremmøte registrert",
    value: "isAllAttendancesRegistered",
  },
  {
    text: "Antall timer",
    value: "start",
  },
];
