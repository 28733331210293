
import { ApiGetCourseParticipantDto } from "@/api/generated/Api";
import TheCourseNewEmailModal from "@/components/courses/section/TheCourseNewEmailModal.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSectionParticipants",
  components: { BaseModal, TheCourseNewEmailModal },
  props: {
    participants: {
      type: Array as PropType<ApiGetCourseParticipantDto[]>,
      required: true,
    },
  },
});
