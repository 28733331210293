
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "WorkingHoursRemainingSpan",
  props: {
    remainingHours: {
      type: Number,
      required: true,
      default: 0,
    },
    totalAmountOfHours: {
      type: Number,
      required: true,
      default: 0,
    },
    displayText: { type: String, required: true },
  },
});
