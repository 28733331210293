var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.course ? _c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(((_vm.course.id) + " - " + (_vm.course.externalTitle))))];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.course ? _c('CourseSectionHeader', {
          attrs: {
            "course": _vm.course,
            "userStatus": _vm.userStatus
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function () {
        return [_c('v-tabs', {
          attrs: {
            "hideSlider": ""
          },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#info"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-information")]), _vm._v(" Informasjon ")], 1), _vm.isCourseOpen ? _c('v-tab', {
          attrs: {
            "href": "#attendance"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-account-clock")]), _vm._v(" Fremmøte ")], 1) : _vm._e(), _c('v-tab', {
          attrs: {
            "href": "#workingHours"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-clock")]), _vm._v(" Arbeidstimer ")], 1), _c('v-tab', {
          attrs: {
            "href": "#participants"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-account-multiple")]), _vm._v(" Deltakere ")], 1)], 1)];
      },
      proxy: true
    }], null, false, 4250794643)
  }, [[_c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "info"
    }
  }, [_vm.course ? _c('CourseSectionInfo', {
    attrs: {
      "course": _vm.course,
      "isVocationalSchool": false,
      "isResource": true
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "participants"
    }
  }, [_c('CourseSectionParticipants', {
    attrs: {
      "participants": _vm.courseParticipantsList
    }
  })], 1), _vm.isCourseOpen ? _c('v-tab-item', {
    attrs: {
      "value": "attendance"
    }
  }, [_c('TheCourseAttendanceList', {
    attrs: {
      "course": _vm.course,
      "scheduledClasses": _vm.course.plan.schedules
    }
  })], 1) : _vm._e(), _c('v-tab-item', {
    attrs: {
      "value": "workingHours"
    }
  }, [_c('WorkingHoursPage')], 1)], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }