
import { api } from "@/api/api";
import BaseLoading from "@/components/shared/loading/BaseLoading.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { withLoadingRef } from "@/shared/helpers/otherHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref, PropType } from "@vue/composition-api";
import { VForm } from "vuetify/lib";

interface Course {
  value: number;
  label: string;
}
export default defineComponent({
  name: "SendMessageModal",
  components: { BaseModalForm, BaseLoading },
  props: {
    courseId: String as PropType<string>,
  },
  setup(props, context) {
    const store = useStore<StoreState>();
    const loading = ref(false);
    const messageForm = ref<InstanceType<typeof VForm> & { validate: () => boolean }>();
    const subject = ref("");
    const message = ref("");
    const recipient = ref<number>();
    const courses = ref<Course[]>([]);
    const selectedRecipient = ref<Course>();

    onMounted(
      withLoadingRef(loading, async () => {
        courses.value = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data.map(
          ({ courseId, course }) => ({ value: courseId, label: course?.externalTitle || "" })
        );
        if (!props.courseId) {
          return;
        }
        recipient.value = +props.courseId;
      })
    );

    const handleSubmit = withLoadingRef(loading, async () => {
      if (!messageForm.value?.validate()) {
        return;
      }
      if (!recipient.value) {
        openNotification(store, NotificationItemType.Error, "Mottaker mangler", 5000);
        return;
      }
      try {
        await api.messaging.sendMessageFromMinSideAsync({
          courseId: recipient.value,
          subject: subject.value,
          text: message.value,
        });
        openNotification(store, NotificationItemType.Success, "Meldingen er sendt", 5000);
        context.emit("close");
      } catch (e) {
        openNotification(store, NotificationItemType.Error, "En feil oppsto under sending av melding", 5000);
      }
    });

    return {
      loading,
      messageForm,
      courses,
      subject,
      message,
      recipient,
      handleSubmit,
      validateNotEmpty,
      selectedRecipient,
      modalType: ModalType,
    };
  },
});
