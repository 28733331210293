
import { api } from "@/api/api";
import { ApiGetMinSideCourseDto } from "@/api/generated/Api";
import CourseSectionInfoChip from "@/components/courses/section/CourseSectionInfoChip.vue";
import BaseInfoField, { BaseFieldInterface } from "@/components/shared/field/BaseInfoField.vue";
import { formatDate, formatLocalizedTime } from "@/shared/helpers/dateHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute } from "@/shared/useHelpers";
import { useIsVocationalSchool } from "@/shared/utils/useIsVocationalSchool";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSectionInfo",
  components: { BaseInfoField, CourseSectionInfoChip },
  props: {
    course: {
      type: Object as PropType<ApiGetMinSideCourseDto>,
      required: true,
    },
    isVocationalSchool: {
      type: Boolean,
      required: false,
    },
    isResource: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const courseId = +route.params.id;
    const address = ref<BaseFieldInterface[]>();
    const infoTexts = ref<any[]>([]);

    const getArea = async () => {
      if (!props.course.courseLocation?.areaId) {
        return;
      }
      return (await api.area.getAreaByIdAsync(props.course.courseLocation.areaId)).data;
    };

    const getLocation = async () => {
      if (!props.course.courseLocation?.locationId || !props.course.courseLocation.areaId) {
        return;
      }
      return (
        await api.area.getLocationAsync(props.course.courseLocation.areaId, props.course.courseLocation.locationId)
      ).data;
    };

    const getRoom = async () => {
      if (
        !props.course.courseLocation?.locationId ||
        !props.course.courseLocation.areaId ||
        !props.course.courseLocation.roomId
      ) {
        return;
      }
      return (
        await api.area.getRoomAsync(
          props.course.courseLocation.areaId,
          props.course.courseLocation.locationId,
          props.course.courseLocation.roomId
        )
      ).data;
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await getAddress();
        setInfoTexts();
      });
    });

    const courseDetails = ref(
      [
        {
          value: courseId,
          label: "Id",
        },
        {
          value: props.course.externalTitle,
          label: "Tittel",
        },

        ...(!useIsVocationalSchool(props.course.mainCourseArea ?? "")
          ? [
              {
                value: "Se kursbestemmelser",
                label: "Avmeldingsgebyr",
              },
            ]
          : []),
        ...(!props.isResource
          ? [
              {
                value: formatCurrency(props.course.economy?.price),
                label: "Pris",
              },
            ]
          : []),
        ...(props.isResource
          ? [
              {
                value: formatDate(props.course.startDate),
                label: "Oppstart",
              },
            ]
          : []),
        ...(props.isResource
          ? [
              {
                value: formatDate(props.course.endDate),
                label: "Slutt",
              },
            ]
          : []),
      ].filter((item) => item.value)
    );

    const getAddress = async () => {
      const [area, location, room] = await Promise.all([getArea(), getLocation(), getRoom()]);
      address.value = [
        {
          value: area?.place,
          label: "Sted",
        },
        {
          value: location?.street,
          label: "Adresse",
        },
        ...(location
          ? [
              {
                value: `${location.postalCode} ${location.postalArea}`,
                label: "Postadresse",
              },
            ]
          : []),
        {
          value: room?.floorNumber,
          label: "Etasje",
        },
        {
          value: room?.name,
          label: "Romnavn",
        },
        {
          value: area?.municipality,
          label: "Kommune",
        },
        {
          value: area?.county,
          label: "Fylke",
        },
      ].filter((item) => item.value);
    };

    const dateAndtime = ref(
      [
        {
          value: formatDate(props.course.startDate),
          label: "Oppstart",
        },
        {
          value: formatDate(props.course.endDate),
          label: "Slutt",
        },
      ].filter((item) => item.value)
    );

    if (props.course.isTimeWebVisible) {
      dateAndtime.value.push(
        {
          value: formatLocalizedTime(props.course.startDate),
          label: "Start klokkeslett",
        },
        {
          value: formatLocalizedTime(props.course.endDate),
          label: "Slutt klokkeslett",
        }
      );
    }

    const setInfoTexts = () => {
      const {
        courseContent,
        intro,
        targetGroup,
        foreknowledgeRequirements,
        learningObjectives,
        method,
        financing,
        exam,
      } = props.course.webDescription || {};

      infoTexts.value = [
        {
          title: "Informasjon",
          text: intro,
        },
        {
          title: "Målgruppe",
          text: targetGroup,
        },
        {
          title: "Opptakskrav",
          text: foreknowledgeRequirements,
        },
        {
          title: "Læringsmål",
          text: learningObjectives,
        },
        {
          title: "Faginnhold",
          text: courseContent,
        },
        {
          title: "Gjennomføring",
          text: method,
        },
        {
          title: "Finansiering",
          text: financing,
        },
        {
          title: "Eksamen",
          text: exam,
        },
      ].filter((x) => x.text);
    };

    return {
      courseId,
      courseDetails,
      address,
      dateAndtime,
      formatDate,
      formatLocalizedTime,
      infoTexts,
      useIsVocationalSchool,
    };
  },
});
