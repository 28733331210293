
import { api } from "@/api/api";
import { ApiGetCourseParticipantDto, ApiGetMinSideCourseDto } from "@/api/generated/Api";
import TheCourseAttendanceList from "@/components/courses/list/TheCourseAttendanceList.vue";
import CourseSectionAttendance from "@/components/courses/section/CourseSectionAttendance.vue";
import CourseSectionHeader from "@/components/courses/section/CourseSectionHeader.vue";
import CourseSectionInfo from "@/components/courses/section/CourseSectionInfo.vue";
import CourseSectionParticipants from "@/components/courses/section/CourseSectionParticipants.vue";
import CourseSectionStaff from "@/components/courses/section/CourseSectionStaff.vue";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import { StatusType } from "@/shared/enums/courseApplicationStatus.enums";
import { CourseStatus } from "@/shared/enums/courseStatus.enum";
import { RolesType } from "@/shared/enums/roleTypesEnum.enums";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import WorkingHoursPage from "./WorkingHoursPage.vue";

export default defineComponent({
  name: "SingleAssignmentCoursePage",
  components: {
    BaseBreadCrumbs,
    CourseSectionHeader,
    CourseSectionInfo,
    CourseSectionStaff,
    CourseSectionParticipants,
    CourseSectionAttendance,
    BaseLayout,
    WorkingHoursPage,
    TheCourseAttendanceList,
  },
  props: {
    routeTab: String,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const courseParticipantsList = ref<ApiGetCourseParticipantDto[]>([]);
    const courseId = +route.params.id;
    const userStatus = route.params.userStatus;

    const course = ref<ApiGetMinSideCourseDto>();

    const currentTab = computed({
      set: (tab: string) => {
        router.replace({ params: { tab } });
      },
      get: () => props.routeTab || "active",
    });

    const getCourse = async () => {
      course.value = (await api.minside.getCourseByIdAsync(courseId)).data;
    };

    const getCourseParticipants = async () => {
      courseParticipantsList.value = (await api.minside.getCourseParticipantsByCourseIdMinsideAsync(courseId)).data
        .filter((participant) => participant.roleName === RolesType.Student)
        .filter((student) => student.status === StatusType.Påmeldt);
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all([getCourse(), getCourseParticipants()]);
      });
    });
    return {
      course,
      currentTab,
      courseParticipantsList,
      courseId,
      userStatus,
      isCourseOpen: computed(() => course.value?.plan?.registerHours && course.value?.status === CourseStatus.Åpnet),
    };
  },
});
