var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', [_c('WorkingHoursModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "workingHoursEntry": _vm.modalData.existingItem,
      "workingHoursEntryId": _vm.modalData.existingItemId,
      "allWorkingHourEntries": _vm.workingHours,
      "contractLines": _vm.contractLines,
      "course": _vm.course
    },
    on: {
      "closeModal": function ($event) {
        _vm.modalData.showModal = false;
      },
      "updateTableValues": _vm.handleUpdate
    }
  })], 1) : _vm._e(), _c('v-data-table', {
    attrs: {
      "data-cy": "workingHoursTable",
      "headers": _vm.headers,
      "search": _vm.search,
      "items": _vm.workingHours,
      "loading": _vm.isLoading,
      "sort-by": "date",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.displayWorkingHourEntry(item, item.costTypeName);
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis oppføring")])]), _vm.workingHoursIsNotApproved(item.status) ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.editWorkingHourEntry(item, item.id);
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger oppføring")])]) : _vm._e(), _vm.workingHoursIsNotApproved(item.status) ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteWorkingHourEntry(item.id);
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-trash-can-outline")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett oppføring")])]) : _vm._e()];
      }
    }, {
      key: "item.date",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDate(value)) + " ")];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }