var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.course ? _c('v-card', [_c('v-tabs', {
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c('v-tab', {
    attrs: {
      "href": "#workingHours"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-clock")]), _vm._v(" Arbeidstimer ")], 1), _c('v-tab', {
    attrs: {
      "href": "#hourBalance"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-clipboard-clock")]), _vm._v(" Timesaldo ")], 1), _c('v-tab', {
    attrs: {
      "href": "#approvedHours"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-check")]), _vm._v(" Godkjente timer ")], 1), _vm.selfEmployedContracts ? _c('v-tab', {
    attrs: {
      "href": "#files"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-file")]), _vm._v(" Genererte filer ")], 1) : _vm._e(), !_vm.isMobile ? [_c('v-spacer'), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "small": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.workingHoursTable.newWorkingHourEntry();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Registrer arbeidstimer ")], 1)] : _vm._e()], 2), _vm.isMobile ? [_c('v-spacer'), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "primary",
      "small": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.workingHoursTable.newWorkingHourEntry();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Registrer arbeidstimer ")], 1)] : _vm._e(), _c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "workingHours"
    }
  }, [_c('WorkingHoursTable', {
    ref: "workingHoursTable",
    attrs: {
      "working-hours": _vm.workingHours,
      "course": _vm.course,
      "courseId": _vm.courseId,
      "contractLines": _vm.contractLines
    },
    on: {
      "workingHoursUpdate": _vm.loadWorkingHoursByCourseId
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "hourBalance"
    }
  }, [_c('ContractTable', {
    attrs: {
      "working-hours": _vm.workingHours,
      "availableContractLines": _vm.availableContractLines
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "approvedHours"
    }
  }, [!_vm.selfEmployedContracts ? _c('WorkingHoursTable', {
    attrs: {
      "working-hours": _vm.approvedWorkingHours,
      "course": _vm.course,
      "courseId": _vm.courseId,
      "contractLines": _vm.contractLines
    }
  }) : _c('ApprovedWorkingHoursTable', {
    attrs: {
      "course": _vm.course,
      "courseId": _vm.courseId
    }
  })], 1), _vm.selfEmployedContracts ? _c('v-tab-item', {
    attrs: {
      "value": "files"
    }
  }, [_c('WorkingHoursGeneratedFiles', {
    attrs: {
      "course": _vm.course,
      "courseId": _vm.courseId
    }
  })], 1) : _vm._e()], 1)], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }