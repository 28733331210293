var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.participants, function (participant) {
    return _c('v-list-item', {
      key: participant.userId,
      staticClass: "px-0"
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-account")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(participant.userFullName)
      }
    })], 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }