var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    attrs: {
      "data-cy": "paymentPendingWorkingHoursTable",
      "headers": _vm.headers,
      "items": _vm.uniquePendingPaymentWorkingHourFiles,
      "sort-by": "fileGeneratedByDate",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.fileGeneratedByDate",
      fn: function (_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDate(value) : "") + " ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var on = _ref3.on,
                attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "syncUserInfoBtn",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.downloadWorkingHourFile(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-file-download-outline")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Last ned PDF")])])];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }