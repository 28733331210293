var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "elevation": 1
    }
  }, [_c('v-card-title', [_c('v-container', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6 font-weight-regular pt-0 pb-0",
    attrs: {
      "data-cy": _vm.dataCy,
      "col": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.sectionTitle) + " ")]), _c('v-col', {
    staticClass: "text-h6 font-weight-regular pt-0 pb-0 d-flex justify-end",
    attrs: {
      "cols": "8"
    }
  }, [_vm._t("sectionHeader"), _c('v-btn', {
    staticClass: "ma-1",
    attrs: {
      "color": "primary",
      "small": "",
      "outlined": "",
      "data-cy": ("showHide-" + (_vm.showHideText))
    },
    on: {
      "click": function ($event) {
        _vm.visible = !_vm.visible;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.visible ? ("Skjul " + (_vm.showHideText)) : ("Vis " + (_vm.showHideText))) + " ")])], 2)], 1)], 1)], 1), _vm.visible ? _c('v-card-text', [_vm._t("sectionBody")], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }